<div class="container-home">
    <section class="container-blue">
        <div class="container d-flex">
            <div>
                <h2>
                    Alteração de recurso
                </h2>
                <p>
                    <i class="bi bi-chevron-right"></i>
                    Processos
                    <i class="bi bi-chevron-right"></i>
                    Alterar Processos
                </p>
            </div>
            <button class="btn-arquivadas" type="button" class="btn" [routerLink]="['/recursos-arquivados']" >
                <i class="bi bi-arrow-return-left"></i>
                Voltar
            </button>
        </div>
    </section>
    <section class="modals">
        <section class="modal-informacao-lote containers d-flex">
            <div class="container-imagem">
                <div  *ngIf="mostrarImagem1" class="container-imagem2" id="primeiraImagem">
                    <lib-ngx-image-zoom
                        *ngIf="mostrarImagem1"
                        [ngClass]="mostrarDoisArquivos?
                            'duas-imagem-zoom' : 'uma-imagem-zoom' "
                        [thumbImage]=imagem1
                        [fullImage]=imagem1
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [enableLens]="true"
                        [lensWidth]="250"
                        [lensHeight]="200"
                        [zoomMode]="'toggle-click'"
                        [scrollStepSize]="0.1"
                    ></lib-ngx-image-zoom >
                    <button class="btn-rotation" (click)="RotacionarImagem('primeiraImagem')"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <div  *ngIf="mostrarImagem2" class="container-imagem2" id="segundaImagem">
                    <lib-ngx-image-zoom
                        *ngIf="mostrarImagem2"
                        [ngClass]="mostrarDoisArquivos?
                            'duas-imagem-zoom' : 'uma-imagem-zoom' "
                        [thumbImage]=imagem2
                        [fullImage]=imagem2
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [enableLens]="true"
                        [lensWidth]="250"
                        [lensHeight]="200"
                        [zoomMode]="'toggle-click'"
                        [scrollStepSize]="0.1"
                    ></lib-ngx-image-zoom >
                    <button class="btn-rotation" (click)="RotacionarImagem('segundaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <div  *ngIf="mostrarImagem3" class="container-imagem2" id="terceiraImagem">
                    <lib-ngx-image-zoom
                        *ngIf="mostrarImagem3"
                        [ngClass]="mostrarDoisArquivos?
                            'duas-imagem-zoom' : 'uma-imagem-zoom' "
                        [thumbImage]=imagem3
                        [fullImage]=imagem3
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [enableLens]="true"
                        [lensWidth]="250"
                        [lensHeight]="200"
                        [zoomMode]="'toggle-click'"
                        [scrollStepSize]="0.1"
                    ></lib-ngx-image-zoom >
                    <button class="btn-rotation" (click)="RotacionarImagem('terceiraImagem')"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <div  *ngIf="mostrarImagem4" class="container-imagem2" id="quartaImagem">
                    <lib-ngx-image-zoom
                        *ngIf="mostrarImagem4"
                        [ngClass]="mostrarDoisArquivos?
                            'duas-imagem-zoom' : 'uma-imagem-zoom' "
                        [thumbImage]=imagem4
                        [fullImage]=imagem4
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [enableLens]="true"
                        [lensWidth]="250"
                        [lensHeight]="200"
                        [zoomMode]="'toggle-click'"
                        [scrollStepSize]="0.1"
                    ></lib-ngx-image-zoom >
                    <button class="btn-rotation" (click)="RotacionarImagem('quartaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <div  *ngIf="mostrarImagem5" class="container-imagem2" id="quintaImagem">
                  <lib-ngx-image-zoom
                      *ngIf="mostrarImagem5"
                      [ngClass]="mostrarDoisArquivos?
                          'duas-imagem-zoom' : 'uma-imagem-zoom' "
                      [thumbImage]=imagem5
                      [fullImage]=imagem5
                      [magnification]="1"
                      [enableScrollZoom]="true"
                      [enableLens]="true"
                      [lensWidth]="250"
                      [lensHeight]="200"
                      [zoomMode]="'toggle-click'"
                      [scrollStepSize]="0.1"
                  ></lib-ngx-image-zoom >
                  <button class="btn-rotation" (click)="RotacionarImagem('quintaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
              </div>
                <app-pdf-viewer *ngIf="mostrarPdf1" [base64Pdf]="imagem1" [exibirDuasPdfs]="mostrarDoisArquivos" [pdfFullViewer]="!mostrarDoisArquivos"></app-pdf-viewer>
                <app-pdf-viewer *ngIf="mostrarPdf2" [base64Pdf]="imagem2" [exibirDuasPdfs]="mostrarDoisArquivos" [pdfFullViewer]="!mostrarDoisArquivos"></app-pdf-viewer>
                <app-pdf-viewer *ngIf="mostrarPdf3" [base64Pdf]="imagem3" [exibirDuasPdfs]="mostrarDoisArquivos" [pdfFullViewer]="!mostrarDoisArquivos"></app-pdf-viewer>
                <app-pdf-viewer *ngIf="mostrarPdf4" [base64Pdf]="imagem4" [exibirDuasPdfs]="mostrarDoisArquivos" [pdfFullViewer]="!mostrarDoisArquivos"></app-pdf-viewer>
                <app-pdf-viewer *ngIf="mostrarPdf5" [base64Pdf]="imagem5" [exibirDuasPdfs]="mostrarDoisArquivos" [pdfFullViewer]="!mostrarDoisArquivos"></app-pdf-viewer>
            </div>
            <div *ngIf="recursoSelecionado" class="modal-informacao">
                <div class="d-flex informacao-infracao">
                    <div class="card">
                        <p>Auto Infração</p>
                        <P>{{recursoSelecionado.autoInfracao}}</P>
                    </div>
                    <div class="card">
                        <p>PLACA</p>
                        <P>{{recursoSelecionado.placa}}</P>
                    </div>
                    <div class="card">
                        <p>Nº protocolo</p>
                        <P>{{recursoSelecionado.numeroProtocolo}}</P>
                    </div>
                    <div class="card">
                        <p>Data protocolo</p>
                        <P>{{recursoSelecionado.dataCriacao | date : 'dd/MM/yyyy' }}</P>
                    </div>
                </div>

                <div class="formulario">
                    <form action="" [formGroup]="validators" class="g-3">
                        <div class="d-flex pt-2">
                            <div class="col-md-6 input-documento">
                                <label for="dataEntrada" class="form-label">Data de entrada</label>
                                <input type="text" class="form-control" formControlName="dataEntrada">
                            </div>
                            <div class="col-md-6">
                                <label for="dataAnalise" class="form-label">Data de análise</label>
                                <input type="text" class="form-control" formControlName="dataAnalise">
                            </div>
                        </div>
                        <div class="pt-3 col-md-12">
                            <label for="nomeInteressado" class="form-label">Nome do interessado</label>
                            <input type="text" class="form-control" formControlName="nomeInteressado" required maxlength="250">
                            <small *ngIf="validators.get('nomeInteressado')?.hasError('required')">
                                Insira o nome do interessado.
                            </small>
                        </div>
                        <div class="d-flex pt-2">
                            <div class="pt-3 col-md-10">
                                <label for="enderecoLogradouro" class="form-label">Endereço</label>
                                <input type="text" class="form-control" formControlName="enderecoLogradouro" [disabled]="true" required maxlength="500">
                            </div>
                            <div class="pt-3 col-md-2">
                                <label for="enderecoNumero" class="form-label">Número</label>
                                <input type="text" class="form-control" formControlName="enderecoNumero" [disabled]="true" required maxlength="25">
                            </div>
                        </div>
                        <div class="pt-3 col-md-12" *ngIf="validators.get('enderecoLogradouro')?.hasError('required') || validators.get('enderecoNumero')?.hasError('required')">
                            <small *ngIf="validators.get('enderecoLogradouro')?.hasError('required')">
                                Insira o endereço do interessado.
                            </small>
                            <small *ngIf="validators.get('enderecoNumero')?.hasError('required')">
                                Insira o número do endereço.
                            </small>
                        </div>
                        <div class="pt-3 col-md-12">
                            <label for="enderecoComplemento" class="form-label">Complemento</label>
                            <input type="text" class="form-control" formControlName="enderecoComplemento" [disabled]="true" maxlength="100">
                        </div>
                        <div class="pt-3 col-md-12">
                            <label for="enderecoBairro" class="form-label">Bairro</label>
                            <input type="text" class="form-control" formControlName="enderecoBairro" [disabled]="true" required maxlength="100">
                            <small *ngIf="validators.get('enderecoBairro')?.hasError('required')">
                                Insira o bairro do interessado.
                            </small>
                        </div>
                        <div class="d-flex pt-2">
                            <div class="col-md-4">
                                <label for="enderecoCep" class="form-label">CEP</label>
                                <input type="text" class="form-control" formControlName="enderecoCep" [disabled]="true" required maxlength="9">
                            </div>
                            <div class="col-md-2">
                                <label for="regiao" class="form-label">UF</label>
                                <select class="form-control" id="estado" formControlName= "regiao" [(ngModel)]="estadoSelecionado" >
                                    <option *ngFor="let estado of estados" [value]="estado">{{estado}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="telefone" class="form-label">Telefone</label>
                                <input type="text" class="form-control" formControlName="telefone" maxlength="15" (input)="onInput($event)">
                            </div>
                        </div>
                        <div class="pt-3 col-md-12" *ngIf="validators.get('telefone')?.invalid && validators.get('telefone')?.touched">
                            <small *ngIf="validators.get('telefone')?.invalid && validators.get('telefone')?.touched">
                                Número de telefone inválido.
                            </small>
                        </div>
                        <div class="pt-3 col-md-12" *ngIf="validators.get('regiao')?.hasError('required') || validators.get('enderecoCep')?.hasError('required')">
                            <small *ngIf="validators.get('enderecoCep')?.hasError('required')">
                                Insira o CEP do interessado.
                            </small>
                            <small *ngIf="validators.get('regiao')?.hasError('required')">
                                Insira o estado do interessado
                            </small>
                            <small *ngIf="validators.get('regiao')?.hasError('minlength')">
                                A UF é inválida.
                            </small>
                        </div>
                        <div class="pt-3 col-md-12">
                            <label for="email" class="form-label">Email</label>
                            <input type="text" class="form-control" formControlName="email" [disabled]="true" maxlength="100">
                            <small *ngIf="validators.get('email')?.hasError('email')">
                                Email inválido.
                            </small>
                        </div>
                        <div class="d-flex pt-2">
                            <div class="tipo-doc pt-6" >
                                <label for="tipoProcesso" class="form-label">Tipo de Processo</label>
                                <input type="text" class="form-control" formControlName="tipoProcesso">
                            </div>
                            <div class="col-md-6">
                                <label for="numeroLote" class="form-label">Lote</label>
                                <input type="text" class="form-control" formControlName="numeroLote" >
                            </div>
                        </div>
                        <div class="pt-3 col-md-12">
                            <label for="usuarioAvaliacao" class="form-label">Usuário avaliação</label>
                            <input type="text" class="form-control" formControlName="usuarioAvaliacao">
                        </div>
                        <div class="pt-3 col-md-12">
                            <div class="form-check-change form-check form-switch">
                                <input class="form-check-input"  id="checkRecursoAceito" type="checkbox" role="switch" formControlName="recursoAceito" required>
                                <label class="form-check-label" for="checkRecursoAceito"> Recurso aceito</label>
                            </div>
                        </div>
                        <div *ngIf="!checkboxRecursoValido" class="pt-3 col-md-12">
                            <label for="motivoArquivamento" class="form-label">Motivo de arquivamento</label>
                            <select type="text" class="form-control" formControlName="motivoArquivamento">
                                <option *ngFor="let motivoArquivamento of motivosArquivamento" [value]="motivoArquivamento.codigoMotivo">{{motivoArquivamento.descricaoMotivo}}</option>
                            </select>
                        </div>
                        <app-multi-checkbox-file *ngIf="carregouImagens" [checkboxs]="checkboxsFile" (checkboxSelectFileEmit)="receberValueCheckboxFile($event)"></app-multi-checkbox-file>
                        <div class="footer-form">
                            <div class="form-check-change form-check form-switch">
                                <input class="form-check-input"  id="checkDocumentos" type="checkbox" role="switch" formControlName="confirmacaoAtualizacaoRecurso" required>
                                <label class="form-check-label" for="checkDocumentos"> Conferi todos os documentos</label>
                            </div>
                            <div class="btn-form">
                                <button class="btn-confirmed" type="submit" (click)="validarRecurso()" [disabled]="!disableBtnAceite()">
                                    <i class="bi bi-check-circle"></i>
                                    Atualizar
                                </button>
                            </div>
                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Alterar Motivo de Arquivamento</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label> {{textoConfirmacaoModal}}</label>
                                    </div>
                                    <div class="modal-footer btn-form">
                                        <button type="button" class="btn-arq" data-bs-dismiss="modal">Cancelar</button>
                                        <button type="button" class="btn-confirmed" data-bs-dismiss="modal" (click)="atualizarRecurso()">Confirmar</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
</section>
</div>
