import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecursoArquivado } from 'src/app/features/recursos/models/recursoArquivado';
import { RecursoSolicitacao } from 'src/app/features/recursos/models/recursoSolicitacao';
import { RecursoService } from 'src/app/features/recursos/service/recurso.service';
import { TipoImagem } from 'src/app/shared/enums/TipoImagem';
import { Documento } from 'src/app/shared/interfaces/documento';
import { MultiCheckbox } from 'src/app/shared/interfaces/multi-checkbox';
import { Buffer} from 'buffer';
import { motivoArquivamentoService } from 'src/app/features/motivo-arquivamento/service/motivo-arquivamento.service';
import { MotivoArquivamento } from 'src/app/features/motivo-arquivamento/models/motivoArquivamento';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { format } from 'path';

declare var $: any;

@Component({
  selector: 'app-edicao-recurso',
  templateUrl: './edicao-recurso.component.html',
  styleUrls: ['./edicao-recurso.component.scss']
})
export class EdicaoRecursoComponent implements OnInit{

  @Input()
  public recursosArquivados:  RecursoSolicitacao[];

  @Input()
  public loteId: number;

  public imagem1: string;
  public imagem2: string;
  public imagem3: string;
  public imagem4: string;
  public imagem5: string;
  public positionImagem = 0;
  public mostrarImagem1: boolean;
  public mostrarImagem2: boolean;
  public mostrarImagem3: boolean;
  public mostrarImagem4: boolean;
  public mostrarImagem5: boolean;
  public mostrarPdf1: boolean;
  public mostrarPdf2: boolean;
  public mostrarPdf3: boolean;
  public mostrarPdf4: boolean;
  public mostrarPdf5: boolean;
  public mostrarDoisArquivos:boolean;

  public recursoSelecionado: RecursoSolicitacao;
  public validators: FormGroup;
  public imagemSelect: string;
  public carregouImagens: boolean;
  public tipoImagem = TipoImagem;
  public checkDocumento: boolean = false;
  public motivosArquivamento: MotivoArquivamento[] = []
  checkboxRecursoValido = false;
  public textoConfirmacaoModal: string = '';
  estadoSelecionado: string;
  public estados: string[] = [
    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA",
    "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN",
    "RS", "RO", "RR", "SC", "SP", "SE", "TO"
  ];

  public tipoProcessos: any[] = [
    {value: "T", key: "Todos"},
    {value: "A", key: "Advertência"},
    {value: "P", key: "Defesa de autuação"},
    {value: "J", key: "Jari"},
    {value: "C", key: "Cetran"}
  ]

  public checkboxsFile: MultiCheckbox[] = []

  constructor( private formBuilder: FormBuilder,
    private recursoService: RecursoService,
    private motivoArquivamentoService: motivoArquivamentoService,
    private route: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute)
  {
    this.validators = this.formBuilder.group(
      {
        dataEntrada: [''],
        dataAnalise: [''],
        nomeInteressado: ['', Validators.required],
        tipoProcesso: [''],
        telefone: ['', Validators.pattern(/^\(\d{2}\) \d{4,5}-\d{4}$/)],
        email: ['', Validators.email],
        numeroLote: ['', Validators.required],
        usuarioAvaliacao: [''],
        regiao: ['', [Validators.required, Validators.minLength(2)]],
        motivoArquivamento: [''],
        confirmacaoAtualizacaoRecurso: ['', Validators.required],
        recursoAceito: [''],
        motivoArq: [0],

        enderecoLogradouro: [''],
        enderecoNumero: [''],
        enderecoComplemento: [''],
        enderecoBairro: [''],
        enderecoCep: [''],
        enderecoMunic: [''],
      },
      { updateOn: 'change' }
    );
  }
  public async  ngOnInit(): Promise<void> {

    var id: number = 0;
    this.activatedRoute.paramMap.subscribe(params => {
      id = +params.get('id')!;
    });
    const dadosRecurso = await this.recursoService.getRecursoATransmitir(id);
    console.log(dadosRecurso);
    this.recursoSelecionado = dadosRecurso;
    this.convertToRecursoSolicitacao(dadosRecurso);
    //await this.definirRecursoSelecionado();
    this.popularFormulario();
    await this.obterImagens();
    await this.obterMotivoArquivamento();
    this.validators.get('dataEntrada')!.disable();
    this.validators.get('dataAnalise')!.disable();
    this.validators.get('numeroLote')!.disable();
    this.validators.get('tipoProcesso')?.disable();
    this.validators.get('usuarioAvaliacao')!.disable();
    // this.validators.get('motivoArquivamento')!.disable();
    this.updateRegiaoValidator();

    //Validacao false ao carregar a pagina
    const validacaoFalse = this.validators.get('confirmacaoAtualizacaoRecurso')!
    validacaoFalse.setValue(0);

    this.checkboxRecursoValido = this.validators.get('recursoAceito')!.value

    if(!this.checkboxRecursoValido){
      this.validators.get('motivoArquivamento')?.setValidators(Validators.required);
      this.validators.get('recursoAceito')?.clearValidators();
  }
    else{
      this.validators.get('recursoAceito')?.setValidators(Validators.required);
      this.validators.get('motivoArquivamento')?.clearValidators();
      this.validators.get('motivoArquivamento')?.setValue(null);
    }

    this.validators.get('recursoAceito')?.updateValueAndValidity();
    this.validators.get('motivoArquivamento')?.updateValueAndValidity();

    this.validators.get('recursoAceito')!.valueChanges.subscribe(value => {
      this.checkboxRecursoValido = value;
      if(!this.checkboxRecursoValido){
        this.validators.get('motivoArquivamento')?.setValidators(Validators.required);
        this.validators.get('recursoAceito')?.clearValidators();
      } else{
        this.validators.get('recursoAceito')?.setValidators(Validators.required);
        this.validators.get('motivoArquivamento')?.clearValidators();
        this.validators.get('motivoArquivamento')?.setValue(null);
      }

      this.validators.get('recursoAceito')?.updateValueAndValidity();
      this.validators.get('motivoArquivamento')?.updateValueAndValidity();
    });
  }
  convertToRecursoSolicitacao(response: any) {
    this.recursoSelecionado.nomeInteressado = response.nomeInteressado;
    this.recursoSelecionado.recursoId = response.id;
    this.recursoSelecionado.statusProcesso = response.status;
    this.recursoSelecionado.tipo = response.tipo;
    this.recursoSelecionado.numeroProtocolo = response.numeroProtocolo;
    this.recursoSelecionado.dataProtocolo = response.dataCriacao;
    this.recursoSelecionado.dataArquivamento = response.dataAnalise;
    this.recursoSelecionado.placa = response.placa;
    this.recursoSelecionado.autoInfracao = response.autoInfracao;
    this.recursoSelecionado.lote = response.loteId;
    this.recursoSelecionado.motivoArquivamento = response.motivoArquivamentoRecurso?.motivo
    this.recursoSelecionado.usuarioAvaliacaoNome = response.usuarioAvaliacaoNome;
    this.recursoSelecionado.telefone = response.celularInteressado;
    this.recursoSelecionado.email = response.emailInteressado;
    this.recursoSelecionado.dataTransmissao = response.dataTransmissao;
    this.recursoSelecionado.dataAnalise = response.dataAnalise;
    this.recursoSelecionado.enderecoLogradouro = response.enderecoLogradouro;
    this.recursoSelecionado.enderecoNumero = response.enderecoNumero;
    this.recursoSelecionado.enderecoComplemento = response.enderecoComplemento;
    this.recursoSelecionado.enderecoBairro = response.enderecoBairro;
    this.recursoSelecionado.enderecoCep = response.enderecoCep;
    this.recursoSelecionado.enderecoUf = response.enderecoUf;
    this.recursoSelecionado.enderecoMunic = response.enderecoMunic;
    this.recursoSelecionado.dataCriacao = response.dataCriacao;
    this.recursoSelecionado.motivoArquivamentoRecursoId = response.motivoArquivamentoRecursoId;
    this.recursoSelecionado.recursoAceito = response.recursoAceito;
  }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = this.applyMask(input.value);
    this.validators.get('telefone')?.setValue(input.value);
  }

  applyMask(value: string): string {

    value = value.replace(/\D/g, '');

    if (value.length > 10) {
      return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else {
      return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
  }

  getTelefoneSemMascara(telefone: string): number | null {
    return telefone ? Number(telefone.replace(/\D/g, '')) : null;
  }

  updateRegiaoValidator() {

    const regiaoControl = this.validators.get('regiao')!
    regiaoControl.setValidators([Validators.required, Validators.minLength(2)]);
    regiaoControl.enable();
    regiaoControl.updateValueAndValidity();
  }

  public retornarTipoDeProcesso(value: string) : string {
    return this.tipoProcessos.filter(x => x.value == value)[0].key;
  }

  public async atualizarRecurso (): Promise<void> {
    var validaAlt = 0;
    debugger;
    const recurso = {
      id: this.recursoSelecionado.recursoId,
      nomeInteressado: this.validators.get('nomeInteressado')?.value,
      emailInteressado : this.validators.get('email')?.value,
      celularInteressado : this.getTelefoneSemMascara(this.validators.get('telefone')?.value)?.toString(),
      enderecoLogradouro : this.validators.get('enderecoLogradouro')?.value,
      enderecoNumero : this.validators.get('enderecoNumero')?.value,
      enderecoComplemento : this.validators.get('enderecoComplemento')?.value,
      enderecoBairro : this.validators.get('enderecoBairro')?.value,
      enderecoCep : this.validators.get('enderecoCep')?.value,
      enderecoUf : this.validators.get('regiao')?.value,
      enderecoMunic : this.validators.get('enderecoMunic')?.value,
      recursoAceito : this.validators.get('recursoAceito')?.value,
      motivoArquivamentoRecursoId : this.validators.get('motivoArquivamento')?.value
    };
    if (
      this.recursoSelecionado.nomeInteressado === this.validators.get('nomeInteressado')?.value &&
      this.recursoSelecionado.email === this.validators.get('email')?.value &&
      this.recursoSelecionado.telefone === this.getTelefoneSemMascara(this.validators.get('telefone')?.value) &&
      this.recursoSelecionado.enderecoLogradouro === this.validators.get('enderecoLogradouro')?.value &&
      this.recursoSelecionado.enderecoNumero === this.validators.get('enderecoNumero')?.value &&
      this.recursoSelecionado.enderecoComplemento === this.validators.get('enderecoComplemento')?.value &&
      this.recursoSelecionado.enderecoBairro === this.validators.get('enderecoBairro')?.value &&
      this.recursoSelecionado.enderecoCep === this.validators.get('enderecoCep')?.value &&
      this.recursoSelecionado.enderecoUf === this.validators.get('regiao')?.value &&
      this.recursoSelecionado.enderecoMunic === this.validators.get('enderecoMunic')?.value &&
      this.recursoSelecionado.recursoAceito === this.validators.get('recursoAceito')?.value &&
      this.recursoSelecionado.motivoArquivamentoRecursoId == this.validators.get('motivoArquivamento')?.value){
        this.toastr.info('Nenhuma informação foi alterada')
        validaAlt = 1
    }

    if (validaAlt == 1){
      this.route.navigate(['/recursos-arquivados']);
    } else {
      try {
        await this.recursoService.postAtualizarRecurso(recurso);
        this.toastr.success('Recurso atualizado.');
        this.route.navigate(['/recursos-arquivados']);
      } catch(error) {
        let errorMessage = 'Erro ao atualizar o recurso.';

        const httpError = error as { error: { code: number; message: string }[] };
        if (httpError.error && Array.isArray(httpError.error) && httpError.error.length > 0) {
          errorMessage = httpError.error[0].message || errorMessage;
        }

        this.toastr.error(errorMessage);
      }
    }

  }

  public receberValueCheckboxFile(checkboxValue: any) : void {
    this.limparImagens();
     this.checkboxsFile.forEach(check => {
      check.value === checkboxValue ?  check.isCheck = !check.isCheck : ''

      const checkboxIsTrue = this.checkboxsFile.filter(x => x.isCheck == true)
      this.mostrarDoisArquivos = checkboxIsTrue.length > 1;

      setTimeout(() => {
        check.isCheck === true ?
        this.mostrarImagemAmpliada(
          new Documento(check.value.base64, check.value.typeDocumento, check.value.tipoImagem))
          :
        this.removerImagemAmpliada(
          new Documento(check.value.base64, check.value.typeDocumento, check.value.tipoImagem));
      }, 500)
    });
  }

  public removerImagemAmpliada(arquivoVisualizacao: Documento): void {
    if (arquivoVisualizacao.tipoImagem === 1) {
      this.mostrarPdf1 = false;
      this.mostrarImagem1 = false;
      this.imagem1 = '';
    }
    else if (arquivoVisualizacao.tipoImagem === 2) {
      this.mostrarPdf2 = false;
      this.mostrarImagem2 = false;
      this.imagem2 = '';
    }
    else if (arquivoVisualizacao.tipoImagem === 3) {
      this.mostrarPdf3 = false;
      this.mostrarImagem3 = false;
      this.imagem3 = '';
    }
    else if (arquivoVisualizacao.tipoImagem === 4) {
      this.mostrarPdf4 = false;
      this.mostrarImagem4 = false;
      this.imagem4 = '';
    }
    else if (arquivoVisualizacao.tipoImagem === 5) {
      this.mostrarPdf5 = false;
      this.mostrarImagem5 = false;
      this.imagem5 = '';
    }
  }

  public mostrarImagemAmpliada(arquivoVisualizacao: Documento): void {
    if(arquivoVisualizacao.typeDocumento === "application/pdf") {
      if(arquivoVisualizacao.tipoImagem === 1) {
        this.mostrarPdf1 = true;
        this.mostrarImagem1 = false;
        this.imagem1 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = true;
        this.mostrarImagem2 = false;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = true;
        this.mostrarImagem3 = false;
        this.imagem3 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 4) {
        this.mostrarPdf4 = true;
        this.mostrarImagem4 = false;
        this.imagem4 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 5) {
        this.mostrarPdf5 = true;
        this.mostrarImagem5 = false;
        this.imagem5 = arquivoVisualizacao.base64;
      }
    }
    else {
      if(arquivoVisualizacao.tipoImagem === 1) {
        this.mostrarPdf1 = false;
        this.mostrarImagem1 = true;
        this.imagem1 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = false;
        this.mostrarImagem2 = true;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = false;
        this.mostrarImagem3 = true;
        this.imagem3 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 4) {
        this.mostrarPdf4 = false;
        this.mostrarImagem4 = true;
        this.imagem4 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 5) {
        this.mostrarPdf5 = false;
        this.mostrarImagem5 = true;
        this.imagem5 = arquivoVisualizacao.base64;
      }
    }
  }

  public RotacionarImagem(imagemId: string) : void {
    const containerImagem = document.getElementById(imagemId);
    const divElementImagem = containerImagem?.getElementsByTagName('div');
    const img = divElementImagem?.item(0);
    this.positionImagem = this.positionImagem + 90;
    img!.style.transform = `rotate(${this.positionImagem}deg)`;
  }


  public disableBtnAceite(): boolean {
    const formGroup: FormGroup = this.validators as FormGroup;

  //   console.log("--------")
  // //Log the state of each control in the FormGroup
  // Object.keys(formGroup.controls).forEach(key => {
  //   const control = formGroup.controls[key];
  //   console.log(`${key} is valid:`, control.valid);
  // });
    return this.validators.valid
  }

  private popularFormulario() {
    this.validators.patchValue({
      dataEntrada: this.formatDate(this.recursoSelecionado.dataCriacao.toString()),
      dataAnalise: this.formatDate(this.recursoSelecionado.dataAnalise.toString()),
      //dataProtocolo: this.recursoSelecionado.dataCriacao,
      nomeInteressado: this.recursoSelecionado.nomeInteressado,
      enderecoLogradouro: this.recursoSelecionado.enderecoLogradouro,
      enderecoNumero: this.recursoSelecionado.enderecoNumero,
      enderecoComplemento: this.recursoSelecionado.enderecoComplemento,
      enderecoBairro: this.recursoSelecionado.enderecoBairro,
      enderecoCep: this.recursoSelecionado.enderecoCep,
      regiao: this.recursoSelecionado.enderecoUf,
      enderecoMunic: this.recursoSelecionado.enderecoMunic,
      tipoProcesso: this.retornarTipoDeProcesso(this.recursoSelecionado.tipo),
      email: this.recursoSelecionado.email,
      telefone: this.recursoSelecionado.telefone ? this.applyMask(this.recursoSelecionado.telefone.toString()) : '',
      numeroLote: this.recursoSelecionado.lote,
      usuarioAvaliacao: this.recursoSelecionado.usuarioAvaliacaoNome,
      confirmacaoAtualizacaoRecurso: this.recursoSelecionado.recursoAceito ? true : false,
      recursoAceito: this.recursoSelecionado.recursoAceito ? true : false,
      motivoArquivamento: this.recursoSelecionado.motivoArquivamentoRecursoId ? this.recursoSelecionado.motivoArquivamentoRecursoId : null,
    })
    this.estadoSelecionado = this.recursoSelecionado.enderecoUf;
    //this.validators.get('motivoArquivamento')!.setValue(this.recursoSelecionado.motivoArquivamentoId);
  }

  formatDate(isoString: string): string {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
    }

    private async obterImagens(): Promise<void> {
      this.checkboxsFile = [];
      const tipoImagens = [1, 2, 3, 4, 5];

      for (const tipo of tipoImagens) {
              const resp = await this.obterImagemDocumento(this.recursoSelecionado.recursoId, tipo);
              if (resp) {
                  const nomeCheckbox = this.checkboxsFile.some(file => file.name === tipo.toString()) ?
                      `${tipo}-${this.checkboxsFile.filter(file => file.name.startsWith(tipo.toString())).length + 1}` :
                      tipo.toString();

                  this.checkboxsFile.push({ name: nomeCheckbox, isCheck: false, value: resp });
              }
      }
      this.carregouImagens = true;
  }

  private async obterImagemDocumento(recursoId: number, type: TipoImagem): Promise<any> {
    try{
      const response = await this.recursoService.getImagenRecurso(recursoId, type);
      const imagemDocumentoType = response.headers.get('Content-Type');
      let imagemDocumentoBase64 = Buffer.from(response.body, 'binary').toString('base64');
      if(imagemDocumentoType != 'application/pdf'){
        imagemDocumentoBase64 = `data:image/png;base64,${imagemDocumentoBase64}`
      }
      return new Documento(imagemDocumentoBase64, imagemDocumentoType, type)
    } catch(error) {
    }
  }

  private limparImagens(): void{
    this.mostrarPdf1 = false;
    this.mostrarImagem1 = false;
    this.imagem1 = "";

    this.mostrarPdf2 = false;
    this.mostrarImagem2 = false;
    this.imagem2 = "";

    this.mostrarPdf3 = false;
    this.mostrarImagem3 = false;
    this.imagem3 = "";

    this.mostrarPdf4 = false;
    this.mostrarImagem4 = false;
    this.imagem4 = "";

    this.mostrarPdf5 = false;
    this.mostrarImagem5 = false;
    this.imagem5 = "";
  }

  private async obterMotivoArquivamento(): Promise<void> {
    try {
      const responseApi = await this.motivoArquivamentoService.getMotivoArquivamentoRecurso();
      responseApi.listaMotivosArquivamento.forEach((x: any) => {
        const motivo = new MotivoArquivamento(x)
        this.motivosArquivamento.push(motivo)
      })
    }catch(error){

    }
  }

  abrirModal(): void {
    $('#exampleModal').modal('show');
  }

  public validarRecurso (): void {
    const recursoAceitoInicial = this.recursoSelecionado.recursoAceito;
    const recursoAceitoFinal = this.validators.get('recursoAceito')?.value;
    const motivoArquivamentoInicial = this.recursoSelecionado.motivoArquivamentoRecursoId;
    const motivoArquivamentoFinal = this.validators.get('motivoArquivamento')?.value;
    const descricaoMtvArqInicial = this.motivosArquivamento.find(x => x.codigoMotivo == motivoArquivamentoInicial)?.descricaoMotivo;
    const descricaoMtvArqFinal = this.motivosArquivamento.find(x => x.codigoMotivo == motivoArquivamentoFinal)?.descricaoMotivo;

    if(recursoAceitoInicial == true){

      if(recursoAceitoFinal == false){
        this.textoConfirmacaoModal = 'Deseja alterar o RECURSO ACEITO para arquivado com motivo ' + descricaoMtvArqFinal + '?';
        this.abrirModal();
      } else {
        this.atualizarRecurso();
      }
    }

    if(recursoAceitoInicial == false){

      if(recursoAceitoFinal == true){
        this.textoConfirmacaoModal = 'Deseja alterar o recurso de arquivada com ' + descricaoMtvArqInicial + ' para RECURSO ACEITO?';
        this.abrirModal();
      }

      if(recursoAceitoFinal == false){
        if(motivoArquivamentoInicial != motivoArquivamentoFinal){
          this.textoConfirmacaoModal = 'Deseja alterar o motivo de arquivamento de ' + descricaoMtvArqInicial + ' para ' + descricaoMtvArqFinal + '?';
          this.abrirModal();
        } else {
          this.atualizarRecurso();
        }
      }
    }
  }

}
