import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { BaseService } from "src/app/core/services/base.service";
import { HttpService } from "src/app/core/services/http.service";
import { TipoImagem } from "src/app/shared/enums/TipoImagem";
import { RecursoSolicitacao } from "../models/recursoSolicitacao";

@Injectable({
    providedIn: 'root',
})
export class RecursoService extends BaseService {

    
    constructor(oAuthService: OAuthService,
        private httpService: HttpService){
        super(oAuthService)
    }

    public async getLotesRecurso(): Promise<any> {        
        const response = this.httpService.get(`${this.baseUrl()}/api/recursolotes/aberto`,
            this.getHeaderJson()
        );
        return this.extractData(response)
    }

    public async vincularLoteAoUsuario(loteId: Number): Promise<void> {
        const response = this.httpService.post(`${this.baseUrl()}/api/recursolotes`,
            loteId,
            this.getHeaderJson()
        );
        return this.extractData(response)
    }

    public async desvincularLoteAoUsuario(loteId: Number): Promise<void> {
        const response = this.httpService.post(`${this.baseUrl()}/api/recursolotes/desvincular`,
            loteId,
            this.getHeaderJson()
        );
        return this.extractData(response)
    }

    public async getRecursos(loteId: number): Promise<any> {     

        const params = new HttpParams().set('loteId', loteId);

        const url = `${this.baseUrl()}/api/recursos/Vinculados`;
    
        const response = await this.httpService.get(url, {headers: this.getHeaders(), params});
    
        return this.extractData(response);
    }

    public async getRecurso(recursoId: number): Promise<any> {
        const params = new HttpParams().set('id', recursoId);

        const url = `${this.baseUrl()}/api/recursos/Id`;

        const response = await this.httpService.get(url, {headers: this.getHeaders(), params});

        return this.extractData(response)
    }

    public async getRecursoATransmitir(recursoId: number): Promise<any> {
        const params = new HttpParams().set('id', recursoId);

        const url = `${this.baseUrl()}/api/recursos/transmitir`;

        const response = await this.httpService.get(url, {headers: this.getHeaders(), params});

        return this.extractData(response)
    }

    public async getRecursosArquivados(dataInicial: string, dataFinal: string, termo: string, skip: number, take: number, tipoProcesso: string, statusTransmissao: string): Promise<any> {     

        const params = new HttpParams()
            .set('dataInicial', dataInicial)
            .set('dataFinal', dataFinal)
            .set('termo', termo)
            .set('skip', skip)
            .set('take', take)
            .set('tipoProcesso', tipoProcesso)
            .set('statusTransmissao', statusTransmissao);

     
        const url = `${this.baseUrl()}/api/recursos/arquivadas`;
    
        const response = await this.httpService.get(url, {headers: this.getHeaders(), params});
    
        return this.extractData(response);
    }

    
    public async getImagenRecurso(recursoId: number, tipoImagem: TipoImagem): Promise<any> {
        const response = await this.httpService.getImg(`${this.baseUrl()}/api/recursos/${recursoId}/imagem/${tipoImagem}`,{headers: this.getHeaders(), responseType: 'arraybuffer', observe: 'response' })
        return this.extractData(response)
    }

    public async postAceitarRecurso(recurso: any): Promise<void> {
        const response = await this.httpService.post(`${this.baseUrl()}/api/recursos/aceitar`, recurso, this.getHeaderJson());
        return this.extractData(response)
    }

    public async postAtualizarRecurso(recurso: any): Promise<void> {
        const response = await this.httpService.post(`${this.baseUrl()}/api/recursos/atualizar`, recurso, this.getHeaderJson());
        return this.extractData(response)
    }

    public async postArquivarRecurso(recurso: any) : Promise<void> {
        const response = await this.httpService.post(`${this.baseUrl()}/api/recursos/arquivar`, recurso, this.getHeaderJson());
        return this.extractData(response)
    }

    public async fecharLote(loteId: number): Promise<void> {
        const params =  new HttpParams().set('loteId', loteId);
        const response = this.httpService.put(`${this.baseUrl()}/api/recursolotes/Fechar`,
        {headers: this.getHeaders(), params: params}
        );
        return this.extractData(response)
    }
    
}