<section class="container-blue">
  <div class="container d-flex">
      <div>
          <h2>
              Processos
              <svg-icon class="icon-condutor" src="../../../assets/icon/law-icon.svg"></svg-icon>
          </h2>
          <hr size="10" width="50%" align="left" class="linha-horizontal">
          <p>Informações do Processo Arquivado.</p>
          <p>
            <i class="bi bi-chevron-right"></i>
              <a href="">Processos</a>
            <i class="bi bi-chevron-right"></i>
              <a href="/recursos-arquivados" class="caminho-pagina">Arquivados</a>
          </p>
      </div>
      <button class="btn-arquivadas" type="button" class="btn" [routerLink]="['/recursos-arquivados']">
        <i class="bi bi-arrow-return-left"></i>
        Voltar
    </button>
  </div>
</section>
<section class="modal-lotes containers">
  <div class="header-modal d-flex">
    <h6 class="bi bi-exclamation-circle">
      Informações detalhadas do processo arquivado
    </h6>
  </div>
  <div class="container-info d-flex" *ngIf="detalheRecursoSelecionado">
    <div class="info-recurso">
      <div class="d-flex line2 flex-wrap">
        <div class="p-2 col-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">MOTIVO ARQUIVAMENTO</h6>
          <p id="mtvArquivamento">{{ detalheRecursoSelecionado.motivoArquivamento }}</p>
        </div>
        <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">AUTO DE INFRAÇÃO</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.autoInfracao}}</p>
        </div>
        <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">PLACA</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.placa }}</p>
        </div>
        <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">PROTOCOLO</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.nrProtocolo }}</p>
        </div>
        <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">DATA RECURSO</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.dataProtocolo| date : 'dd/MM/YYYY'  }}</p>
        </div>
        <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">NOME INTERESSADO</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.nomeInteressado }}</p>
        </div>
        <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">EMAIL</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.email }}</p>
        </div>
        <div class="p-2 col-md-6 col-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">DATA DE ARQUIVAMENTO</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.dataArquivamento | date : 'd/MM/y'}}</p>
        </div>
        <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">TELEFONE</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.telefone }}</p>
        </div>
        <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
          <h6 class="titulos-campos">USUÁRIO AVALIAÇÃO</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.userAvaliacaoNome }}</p>
        </div>
        <div class="p-2 col-6" style="padding:0.1rem !important">
          <h6 class="titulos-campos">LOTE</h6>
          <p class="conteudo-campos">{{ detalheRecursoSelecionado.lote}}</p>
        </div>
      </div>
      <app-multi-checkbox-file *ngIf="carregouImagens" [checkboxs]="checkboxsFile" (checkboxSelectFileEmit)="receberValueCheckboxFile($event)"></app-multi-checkbox-file>
      <div class="container-btn-editar col-12">
        <button *ngIf="!detalheRecursoSelecionado.dataTransmissao" type="button" class="btn-editar" (click)="openEdicaoRecurso(detalheRecursoSelecionado.recursoId)">
          <i class="bi bi-pencil-square"></i>
          Editar
        </button>
      </div>
    </div>
    <div class="container-imagem">
      <div *ngIf="detalheRecursoSelecionado">
        <div class="p-2">
          <h6 class="conteudo-campos p-2">Tipo de Processo: {{ TraducaoTipoRecurso[detalheRecursoSelecionado.tipoRecurso] ?? 'Desconhecido' }} </h6>
        </div>
      </div>
      <div class="imagens-recurso imagem d-flex col-6 justify-content-center">
        <div  *ngIf="mostrarImagem1" class="container-imagem2" id="primeiraImagem">
          <lib-ngx-image-zoom
              *ngIf="mostrarImagem1"
              [ngClass]="mostrarDoisArquivos?
                  'duas-imagem-zoom' : 'uma-imagem-zoom' "
              [thumbImage]=imagem1
              [fullImage]=imagem1
              [magnification]="1"
              [enableScrollZoom]="true"
              [enableLens]="true"
              [lensWidth]="250"
              [lensHeight]="200"
              [zoomMode]="'toggle-click'"
              [scrollStepSize]="0.1"
          ></lib-ngx-image-zoom >
          <button class="btn-rotation" (click)="RotacionarImagem('primeiraImagem')"><i class="bi bi-arrow-clockwise"></i></button>
      </div>
      <div  *ngIf="mostrarImagem2" class="container-imagem2" id="segundaImagem">
          <lib-ngx-image-zoom
              *ngIf="mostrarImagem2"
              [ngClass]="mostrarDoisArquivos?
                  'duas-imagem-zoom' : 'uma-imagem-zoom' "
              [thumbImage]=imagem2
              [fullImage]=imagem2
              [magnification]="1"
              [enableScrollZoom]="true"
              [enableLens]="true"
              [lensWidth]="250"
              [lensHeight]="200"
              [zoomMode]="'toggle-click'"
              [scrollStepSize]="0.1"
          ></lib-ngx-image-zoom >
          <button class="btn-rotation" (click)="RotacionarImagem('segundaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
      </div>
      <div  *ngIf="mostrarImagem3" class="container-imagem2" id="terceiraImagem">
          <lib-ngx-image-zoom
              *ngIf="mostrarImagem3"
              [ngClass]="mostrarDoisArquivos?
                  'duas-imagem-zoom' : 'uma-imagem-zoom' "
              [thumbImage]=imagem3
              [fullImage]=imagem3
              [magnification]="1"
              [enableScrollZoom]="true"
              [enableLens]="true"
              [lensWidth]="250"
              [lensHeight]="200"
              [zoomMode]="'toggle-click'"
              [scrollStepSize]="0.1"
          ></lib-ngx-image-zoom >
          <button class="btn-rotation" (click)="RotacionarImagem('terceiraImagem')"><i class="bi bi-arrow-clockwise"></i></button>
      </div>
      <div  *ngIf="mostrarImagem4" class="container-imagem2" id="quartaImagem">
          <lib-ngx-image-zoom
              *ngIf="mostrarImagem4"
              [ngClass]="mostrarDoisArquivos?
                  'duas-imagem-zoom' : 'uma-imagem-zoom' "
              [thumbImage]=imagem4
              [fullImage]=imagem4
              [magnification]="1"
              [enableScrollZoom]="true"
              [enableLens]="true"
              [lensWidth]="250"
              [lensHeight]="200"
              [zoomMode]="'toggle-click'"
              [scrollStepSize]="0.1"
          ></lib-ngx-image-zoom >
          <button class="btn-rotation" (click)="RotacionarImagem('quartaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
      </div>
      <div  *ngIf="mostrarImagem5" class="container-imagem2" id="quintaImagem">
        <lib-ngx-image-zoom
            *ngIf="mostrarImagem5"
            [ngClass]="mostrarDoisArquivos?
                'duas-imagem-zoom' : 'uma-imagem-zoom' "
            [thumbImage]=imagem5
            [fullImage]=imagem5
            [magnification]="1"
            [enableScrollZoom]="true"
            [enableLens]="true"
            [lensWidth]="250"
            [lensHeight]="200"
            [zoomMode]="'toggle-click'"
            [scrollStepSize]="0.1"
        ></lib-ngx-image-zoom >
        <button class="btn-rotation" (click)="RotacionarImagem('quintaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
    </div>
      <app-pdf-viewer *ngIf="mostrarPdf1" [base64Pdf]="imagem1" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
      <app-pdf-viewer *ngIf="mostrarPdf2" [base64Pdf]="imagem2" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
      <app-pdf-viewer *ngIf="mostrarPdf3" [base64Pdf]="imagem3" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
      <app-pdf-viewer *ngIf="mostrarPdf4" [base64Pdf]="imagem4" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
      <app-pdf-viewer *ngIf="mostrarPdf5" [base64Pdf]="imagem5" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
      </div>
    </div>
  </div>
</section>
